<template>
    <div class="item-container">
        <div v-if="is_credit" class="credit-label">Credit</div>
        <el-form ref="lineitem_form" :model="form">
            <div class="item-row">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="10">
                        <div class="row description">
                            <el-input
                                v-model="form.title"
                                class="left"
                                name="email"
                                type="text"
                                @blur="updateLineItemTitle"
                            />
                        </div>
                    </el-col>

                    <!-- COST -->
                    <el-col :span="3">
                        <div class="row">
                            <span class="el-input__inner input-value read-only">
                                {{ (line_item.cost * sign) | currency }}
                            </span>
                        </div>
                    </el-col>

                    <!-- DISCOUNT -->
                    <el-col :span="2">
                        <div class="row">
                            <span class="el-input__inner input-value read-only">
                                {{ line_item.discount ?? 0 }}
                            </span>
                        </div>
                    </el-col>

                    <!-- DISCOUNTED COST -->
                    <el-col :span="3">
                        <div class="row">
                            <span class="el-input__inner input-value read-only">
                                {{ (discounted_cost * sign) | currency }}
                            </span>
                        </div>
                    </el-col>

                    <!-- INVOICED% -->
                    <el-col :span="2">
                        <div class="row">
                            <span class="el-input__inner input-value read-only">
                                {{ invoiced_percent }}
                            </span>
                        </div>
                    </el-col>

                    <!-- REMAINING -->
                    <el-col :span="3">
                        <div class="row">
                            <span class="el-input__inner input-value read-only">
                                {{ (remaining * sign) | currency }}
                            </span>
                        </div>
                    </el-col>

                    <!-- INCLUDE CHECKBOX -->
                    <el-col :span="1">
                        <div class="row">
                            <el-checkbox
                                v-model="selected"
                                :disabled="!is_invoiceable"
                            />
                        </div>
                    </el-col>

                    <!-- PERCENT -->
                    <el-col :span="2">
                        <div class="row">
                            <el-input-number
                                v-if="selected && !is_zero_amount"
                                ref="input_percent"
                                v-model="form.percent"
                                class="input-value"
                                :controls="false"
                                size="small"
                                placeholder="0"
                                :min="0"
                                :max="100 - invoiced_percent"
                                :precision="1"
                                :step="5"
                                :disabled="!discounted_cost"
                                @change="validatePercent"
                                @focus="selectInput"
                            />
                            <span
                                v-else
                                class="el-input__inner input-value read-only"
                            >
                                -
                            </span>
                        </div>
                    </el-col>

                    <!-- AMOUNT -->
                    <el-col :span="3">
                        <div class="row">
                            <span
                                v-if="selected && is_credit"
                                class="credit-prefix"
                            >
                                -
                            </span>
                            <money
                                v-if="selected"
                                ref="input_amount"
                                v-model="form.amount"
                                v-bind="money"
                                class="el-input__inner el-input--small"
                                :disabled="is_zero_amount"
                                @blur.native="validateAmount"
                                @focus.native="selectInput"
                            />
                            <span
                                v-else
                                class="el-input__inner input-value read-only"
                            >
                                -
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import {VMoney} from 'v-money';
import PaymentplanMixin from '@/mixins/paymentplan.mixin';
import {CURRENCY_TOL} from '@/utils/constants';

export default {
    name: 'paymentplan-line-item',
    directives: {money: VMoney},
    mixins: [PaymentplanMixin],
    props: {
        line_item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: {
                title: this.line_item.title,
                percent: 0,
                amount: 0,
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
            selected: false,
        };
    },
    computed: {
        is_credit() {
            return this.line_item.credit;
        },
        is_zero_amount() {
            return this.discounted_cost === 0;
        },
        sign() {
            return this.is_credit ? -1 : 1;
        },
        discounted_cost() {
            return this.discounted(this.line_item.cost);
        },
        invoiced_percent() {
            if (this.is_zero_amount) {
                return this.line_item.is_invoiced ? 100 : 0;
            }

            return Number(
                ((this.line_item.invoiced / this.line_item.cost) * 100).toFixed(
                    1
                )
            );
        },
        remaining() {
            return this.discounted(
                this.line_item.cost - this.line_item.invoiced
            );
        },
        line_item_qty() {
            return this.line_item.override?.qty || this.line_item.qty;
        },
        is_invoiceable() {
            if (this.is_zero_amount) return false;
            if (this.line_item.is_invoiced) return false;
            return Math.abs(this.remaining) > CURRENCY_TOL;
        },
    },
    watch: {
        selected(sel) {
            if (sel === true) {
                // default to maximum values
                this.form.amount = this.remaining;
                this.validateAmount();
            } else {
                this.updateValues();
            }
        },
        remaining(val) {
            if (this.is_zero_amount) return;

            if (val > 0) {
                this.validateAmount();
            } else {
                this.selected = false;
            }
        },
        line_item(item) {
            if (this.is_zero_amount) return;

            this.selected = item.selected;
        },
    },
    mounted() {
        // $0 items are selected by default and un-selectable
        // They are always included in invoices plans
        if (this.is_zero_amount) {
            this.selected = true;
        }
    },
    methods: {
        discounted(value) {
            return this.discountValue(value, this.line_item.discount);
        },
        undiscounted(value) {
            return this.undiscountValue(value, this.line_item.discount);
        },
        validateAmount() {
            // check if input amount is within bounds
            if (this.cr) if (this.form.amount < 0) this.form.amount = 0;
            if (this.form.amount > this.remaining)
                this.form.amount = this.remaining;

            // recalculate percent
            if (this.is_zero_amount) {
                this.form.percent = 100;
            } else {
                this.form.percent =
                    (this.form.amount / this.discounted_cost) * 100;
            }

            this.updateValues();
        },
        validatePercent() {
            // check if input percent is within bounds
            if (this.form.percent < 0) this.form.percent = 0;
            if (this.form.percent > 100 - this.invoiced_percent)
                this.form.percent = 100 - this.invoiced_percent;

            // recalculate amount
            this.form.amount = (this.discounted_cost * this.form.percent) / 100;
            // in case of rounding issues, cap invoice amount to remaining
            if (this.form.amount > this.remaining)
                this.form.amount = this.remaining;

            this.updateValues();
        },
        updateValues() {
            // get the selected amount for this line item
            // (special case for 100% discounted items: selected_amount should be the full undiscounted cost)
            const selected_amount =
                this.line_item.discount === 100
                    ? this.line_item.cost
                    : this.undiscounted(this.form.amount);

            // get the proportional qty for this line item
            // (special case for 0 cost items: qty should be the full line item qty)
            const qty =
                this.line_item.discount === 100 || this.line_item.cost === 0
                    ? this.line_item_qty
                    : this.line_item.initial_qty *
                      (this.form.amount / this.discounted_cost);

            this.$emit('update', {
                ...this.line_item,
                title: this.form.title,
                selected: this.selected,
                selected_amount: this.undiscounted(this.form.amount),
                qty,
                rate: this.line_item.rate,
            });
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
        updateLineItemTitle() {
            if (this.line_item.credit) {
                const params = {
                    id: this.$options.filters.fireRef2id(
                        this.line_item.estimate
                    ),
                    'credit.label': this.form.title,
                };
                this.$store.dispatch('updateEstimate', params);
            } else {
                const params = {
                    group_id: this.line_item.id,
                    title: this.form.title,
                };
                this.$store.dispatch('renameEstimateGroup', params);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    margin-bottom: 5px;

    .item-row {
        padding: 2px 0 4px;
    }
}

.row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

::v-deep .input-value {
    width: 100%;

    &.read-only,
    &.read-only input,
    input:read-only {
        background: transparent;
        color: #a0a0a0;
        pointer-events: none;
        border: none;
    }

    input {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
    }
}

::v-deep .el-input {
    .el-input__inner {
        height: 32px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        font-family: Montserrat, sans-serif;

        &.read-only,
        &:read-only {
            border: 1px solid $border-grey !important;
            background: transparent;
        }

        .small {
            font-size: 11px;
        }

        &.bold {
            font-weight: bold;
        }
    }
}

.description {
    display: flex;

    .el-input__inner {
        justify-content: flex-start;
    }
}

.credit-label {
    font-size: 11px;
    color: $black;
    margin: 10px 0 6px 0;
}

.credit-prefix {
    position: absolute;
    left: 10px;
}
</style>
