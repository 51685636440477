<template>
    <div v-if="estimate">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref">
                /
                <span> {{ estimate.ref }} - Payment plan</span>
            </span>
            <el-button
                v-if="!is_voided && !payment_plans.length"
                type="danger"
                plain
                class="voidButton"
                @click="handleVoidPayment"
            >
                Void payment
            </el-button>
        </div>

        <hr />

        <div v-if="is_voided" class="voided">
            <el-alert show-icon type="error" :closable="false">
                Payment has been voided for this estimate
            </el-alert>
        </div>
        <template v-else>
            <paymentplan-create
                v-if="new_payment_plan"
                :estimate="estimate"
                :payment_plan="new_payment_plan"
                @reference="handleUpdateReference"
                @purchase_order="handleSetNewPurchaseOrder"
                @cancel="handleCancelCreate"
                @create="handleSavePaymentPlan"
            />

            <paymentplan-form
                v-show="!new_payment_plan && invoiceable"
                :estimate="estimate"
                :line_items="billable_line_items"
                @create="handleCreatePaymentPlan"
            />

            <div v-if="!new_payment_plan">
                <div
                    v-for="plan in payment_plans"
                    :key="plan.id"
                    class="payment-plan"
                >
                    <div class="payment-plan-header">
                        <div class="payment-plan-ref">{{ plan.id }}</div>

                        <div class="payment-plan-controls">
                            <template
                                v-if="
                                    !loading.payment_plan_invoices &&
                                    (isPurchaseOrderEditable(plan) ||
                                        plan.purchase_order)
                                "
                            >
                                Purchase order:
                                <el-input
                                    class="purchase-order"
                                    size="mini"
                                    :disabled="!isPurchaseOrderEditable(plan)"
                                    v-model="plan.purchase_order"
                                    @change="
                                        () => handleUpdatePurchaseOrder(plan)
                                    "
                                />
                            </template>
                            <el-button
                                class="payment-plan-edit"
                                size="mini"
                                :type="
                                    !plan.contact_emails ? 'danger' : 'default'
                                "
                                @click="
                                    () => {
                                        editPlanAction(plan);
                                    }
                                "
                            >
                                Edit contacts
                            </el-button>
                        </div>
                    </div>
                    <loader v-if="loading.payment_plan_invoices" />
                    <div v-else class="payment-plan-invoices">
                        <payment-plan-invoice-block
                            v-for="invoice in getInvoices(plan)"
                            :key="invoice.id"
                            :invoice="invoice"
                            :project_ref="projectRef"
                            :estimate="estimate"
                            :invoices_count="getInvoices(plan).length"
                            @click="handleClickInvoice"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import PaymentPlanInvoiceBlock from '@/components/blocks/PaymentPlanInvoiceBlock';
import Loader from '@/components/generic/Loader';

import PaymentplanForm from './components/PaymentPlan_form';
import PaymentplanCreate from './components/PaymentPlan_create';

import {Notification} from 'element-ui';

import PaymentplanMixin from '@/mixins/paymentplan.mixin';
import {CURRENCY_TOL} from '@/utils/constants';
import {fireRef2id} from '@/filters';
import {PAYMENT_PLAN_INVOICE_STATUS} from '@/enums';

export default {
    name: 'payment-plan-detail',
    components: {
        Loader,
        PaymentplanForm,
        PaymentplanCreate,
        PaymentPlanInvoiceBlock,
    },
    mixins: [PaymentplanMixin],
    data() {
        return {
            line_items: [],
            new_payment_plan: null,
            payment_plan_invoices: [],
            loading: {
                line_items: true,
                payment_plan_invoices: true,
            },
            creating: false,
        };
    },
    computed: {
        estimate() {
            return this.$store.getters.estimateWithId(
                this.$route.params.estimate_id
            );
        },
        payment_plans() {
            return this.$store.getters.paymentPlansForEstimateWithId(
                this.$route.params.estimate_id
            );
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        projectRef() {
            return this.project.ref;
        },
        new_ref() {
            // max_ref_number is auto-incremented by cloud function upon save
            const max_ref_number = this.project.max_ref_number || 0;
            return `${this.project.ref}-P-${`${max_ref_number + 1}`.padStart(
                3,
                '0'
            )}`;
        },
        billable_line_items() {
            let items = this.line_items
                .filter((li) => !li.excluded)
                .map((li) => {
                    const cost = li.qty * this.estimate.rate;

                    const invoiced = this.payment_plan_invoices.reduce(
                        (sum, inv) => {
                            return (
                                sum +
                                inv.line_items.reduce((sum, ppli) => {
                                    // sum up matching line items
                                    if (
                                        this.$options.filters.fireRef2id(
                                            ppli.estimate_group
                                        ) === li.id
                                    ) {
                                        return sum + ppli.amount;
                                    }
                                    return sum;
                                }, 0)
                            );
                        },
                        0
                    );
                    return {
                        ...li,
                        id: li.id,
                        initial_qty: li.override?.qty ?? li.qty,
                        cost,
                        invoiced,
                    };
                });

            if (this.line_items.length && this.estimate?.credit?.value) {
                // get invoiced credit amounts for this estimate
                const creditInvoiced = this.payment_plan_invoices.reduce(
                    (sum, inv) => {
                        const ppli = inv.line_items.find((li) => li.credit);
                        if (ppli) return sum + ppli.amount;
                        return sum;
                    },
                    0
                );

                items.push({
                    id: 'CREDIT',
                    estimate: this.$fire.doc(`estimates/${this.estimate.id}`),
                    credit: true,
                    cost: this.estimate.credit.value,
                    discount: 0,
                    invoiced: creditInvoiced,
                    qty: 1,
                    rate: this.estimate.credit.value,
                    title: this.estimate.credit.label || 'Credit',
                    sort: Number.MAX_VALUE, // always last
                });
            }

            return items;
        },

        invoiceable() {
            if (this.loading.line_items) return false;
            for (const item of this.billable_line_items) {
                const discountedCost = this.discountValue(
                    item.cost,
                    item.discount
                );

                if (
                    !(discountedCost === 0 && item.is_invoiced) &&
                    Math.abs(item.cost - item.invoiced) > CURRENCY_TOL
                ) {
                    return true;
                }
            }
            return false;
        },

        is_voided() {
            return !!this.estimate.payment_voided;
        },
    },
    watch: {
        estimate(newVal, oldVal) {
            if (newVal.id !== oldVal.id) {
                this.init();
            }
        },
    },
    mounted() {
        if (!this.project || !this.estimate) {
            this.$router.replace({name: 'projects'});
            return;
        }

        this.init();
    },
    methods: {
        async init() {
            this.loading.line_items = true;
            this.loading.payment_plan_invoices = true;

            await this.$bind(
                'line_items',
                this.$fire
                    .collection('estimate_groups')
                    .where(
                        'estimate',
                        '==',
                        this.$fire.doc(`estimates/${this.estimate.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );

            /* PAYMENT PLAN INVOICES FOR ESTIMATE */

            await this.$bind(
                'payment_plan_invoices',
                this.$fire
                    .collection('payment_plan_invoices')
                    .where(
                        'estimate',
                        '==',
                        this.$fire.doc(`estimates/${this.estimate.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );

            this.loading.line_items = false;
            this.loading.payment_plan_invoices = false;
        },

        editPlanAction(plan) {
            this.$router.push({
                name: 'project_detail_paymentplan_plan',
                params: {
                    plan_id: plan.id,
                },
            });
        },

        goBackAction() {
            if (this.new_payment_plan) this.new_payment_plan = null;
            else {
                this.$router.push({
                    name: 'project_detail_estimates',
                    params: {
                        project_id: this.$route.params.project_id,
                        estimate_id: this.estimate.id,
                    },
                });
            }
        },

        getInvoices(plan) {
            return this.payment_plan_invoices
                .filter((ppi) => {
                    return (
                        plan.id ===
                        this.$options.filters.fireRef2id(ppi.payment_plan)
                    );
                })
                .map((ppi) => {
                    return {
                        ...ppi,
                        id: ppi.id,
                        total: ppi.line_items.reduce((sum, item) => {
                            const sign = item.credit ? -1 : 1;
                            return (sum +=
                                item.amount *
                                ((100 - (item.discount || 0)) / 100) *
                                sign);
                        }, 0),
                    };
                })
                .sort((a, b) => a.sequence - b.sequence);
        },

        handleCreatePaymentPlan(plan) {
            this.new_payment_plan = plan;
        },
        async handleSavePaymentPlan(invoices, contact_emails) {
            this.creating = true;

            const planRef = this.new_ref;

            try {
                // clean up plan data
                const plan = {
                    ...this.new_payment_plan,
                    ref: planRef,
                    contact_emails,
                    created_by: this.$fire.doc(
                        `users/${this.$store.getters.user.id}`
                    ),
                };
                // convert dates if required
                if (plan.start_date && plan.start_date.toDate) {
                    plan.start_date = plan.start_date.toDate();
                }
                if (plan.due_date && plan.due_date.toDate) {
                    plan.due_date = plan.due_date.toDate();
                }
                delete plan.selected_line_items;

                plan.project = this.$fire.doc(this.estimate.project);
                plan.client = this.$fire.doc(this.estimate.client);

                // Save payment plan
                await this.$store.dispatch('addPaymentPlan', plan);

                const zeroCostLineItemGroups = [];

                for (const invoice of invoices) {
                    // clean up invoice data
                    if (invoice.schedule_date && invoice.schedule_date.toDate) {
                        invoice.schedule_date = invoice.schedule_date.toDate();
                    }
                    if (invoice.due_date && invoice.due_date.toDate) {
                        invoice.due_date = invoice.due_date.toDate();
                    }

                    invoice.line_items.forEach((item) => {
                        delete item.plan_cost;
                        if (!item.discount) item.discount = 0;
                        // if item cost is zero, estimate group is to be marked as invoiced
                        if (
                            Math.abs(
                                this.discountValue(item.amount, item.discount)
                            ) < CURRENCY_TOL
                        ) {
                            zeroCostLineItemGroups.push(item.estimate_group);
                        }
                    });
                    invoice.payment_plan = this.$fire.doc(
                        `payment_plans/${planRef}`
                    );
                    invoice.estimate = plan.estimate;
                    invoice.project = plan.project;
                    invoice.client = plan.client;

                    invoice.status = PAYMENT_PLAN_INVOICE_STATUS.DRAFT;

                    await this.$store.dispatch(
                        'addPaymentPlanInvoice',
                        invoice
                    );
                }

                // if any line items were zero cost, mark their groups as invoiced on the estimate
                // so they are not invoiced again and the payment plan can be completed
                zeroCostLineItemGroups.forEach((group) => {
                    group.update({is_invoiced: true});
                });

                this.new_payment_plan = null;
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            } finally {
                this.creating = false;
            }

            // TODO recalculate invoiced amounts for line items
        },

        handleUpdateReference(newRef) {
            this.new_payment_plan.reference = newRef;
        },

        handleCancelCreate() {
            this.new_payment_plan = null;
        },

        handleClickInvoice(invoice, newWindow) {
            if (newWindow) {
                const project_id = fireRef2id(invoice.project);
                window.open(
                    `/project/${project_id}/paymentplan/invoice/${invoice.id}`,
                    'invoice',
                    'popup'
                );
            } else {
                this.$router.push({
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: this.$route.params.project_id,
                        invoice_id: invoice.id,
                    },
                });
            }
        },

        // Mark this estimate as having payment voided.
        // It will not show up in any uninvoiced lists.
        async handleVoidPayment() {
            this.creating = true;
            try {
                await this.$fire.doc(`estimates/${this.estimate.id}`).update({
                    payment_voided: new Date(),
                });

                this.new_payment_plan = null;

                await this.$router.push({
                    name: 'project_detail_estimates',
                    params: {
                        project_id: this.$route.params.project_id,
                        estimate_id: this.estimate.id,
                    },
                });
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            } finally {
                this.creating = false;
            }
        },

        isPurchaseOrderEditable(plan) {
            // purchase order is only editable if no invoices have been authorised
            const invoices = this.getInvoices(plan);
            if (invoices.some((i) => i.invoice_id)) {
                return false;
            }
            return true;
        },

        async handleUpdatePurchaseOrder(plan) {
            await this.$fire.doc(`payment_plans/${plan.id}`).update({
                purchase_order: plan.purchase_order,
            });
        },
        async handleSetNewPurchaseOrder(po) {
            this.new_payment_plan.purchase_order = po;
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }

    .voidButton {
        margin-left: auto;
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.payment-plan {
    margin-top: 10px;
    padding: 10px 20px 20px 20px;
    background-color: $grey;
    border: 1px solid $border-grey-light;
    border-radius: 4px;
    min-height: 100px;
    position: relative;

    .loader {
        float: left;
        margin-top: 30px;
    }

    .payment-plan-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $border-grey-light;
        padding: 5px 10px;
        margin: -10px -10px 5px;

        .payment-plan-ref {
            flex: 1;
            font-size: 12px;
            font-weight: bold;
        }

        .payment-plan-controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-size: 10px;

            .purchase-order {
                width: 120px;
            }
        }
    }
}
</style>
