<template>
    <div class="wrapper">
        <el-row align="left" class="paymentplan-header">
            <el-col :span="14"><span>Reference template</span></el-col>
            <el-col :span="10"><span>Purchase order</span></el-col>
        </el-row>

        <el-row
            class="paymentplan-form-fields"
            type="flex"
            align="middle"
            :gutter="5"
        >
            <el-col :span="14">
                <el-input v-model="reference" class="input-value left">
                    <el-button
                        slot="prepend"
                        icon="el-icon-question"
                        @click="toggleReferenceTemplateHelp"
                    />
                </el-input>
            </el-col>
            <el-col :span="10">
                <el-input
                    v-model="purchase_order"
                    class="input-value left"
                    @blur="handleUpdatePurchaseOrder"
                >
                </el-input>
            </el-col>
        </el-row>

        <paymentplan-reference-help
            v-if="showReferenceTemplateHelp"
            @close="toggleReferenceTemplateHelp"
        />

        <div class="footer-note" style="margin-top: 10px;">
            Changes to the master reference template will override any custom
            references below:
        </div>

        <paymentplan-invoice
            v-for="invoice in invoices"
            :key="invoice.sequence"
            :estimate="estimate"
            :payment_plan="payment_plan"
            :invoice="invoice"
            :draft="true"
            @update="handleInvoiceUpdate"
        />

        <paymentplan-totals
            :payment_plan="payment_plan"
            :totals="plan_totals"
        />

        <paymentplan-emails
            :payment_plan="payment_plan"
            @change="handleUpdateContactEmails"
        />

        <div class="footer-note">
            * Any dates in references for unscheduled invoices are displayed
            using today's date
        </div>

        <div class="paymentplan-form-footer">
            <el-button @click="handleCancel">Cancel</el-button>
            <el-button :disabled="is_disabled" @click="handleCreate">
                Create
            </el-button>
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';
import {CURRENCY_TOL} from '@/utils/constants';

import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

import PaymentplanReferenceHelp from './PaymentPlan_reference_help';

import PaymentplanInvoice from '@/pages/paymentplans/components/PaymentPlan_invoice';
import PaymentplanTotals from '@/pages/paymentplans/components/PaymentPlan_totals';
import PaymentplanEmails from '@/pages/paymentplans/components/PaymentPlan_emails.vue';

import {EMAIL_TYPE} from '@/enums';

export default {
    name: 'paymentplan-create',
    components: {
        PaymentplanEmails,
        PaymentplanInvoice,
        PaymentplanTotals,
        PaymentplanReferenceHelp,
    },
    mixins: [PaymentPlanMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        payment_plan: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            reference: '',
            purchase_order: null,
            invoices: [],
            showReferenceTemplateHelp: false,
            plan_totals: [],
            contact_emails: [],
        };
    },
    computed: {
        is_disabled() {
            // check if line item totals are equal to or under the payment plan amounts
            for (const row of this.plan_totals) {
                if (row.amount > row.plan_cost + CURRENCY_TOL) {
                    return true;
                }
            }

            // check if all invoices are valid
            for (const invoice of this.invoices) {
                // if scheduled, must have a scheduled date
                if (invoice.is_scheduled && !invoice.schedule_date) {
                    return true;
                }
                // check the sum of line items is positive (in case credit was applied)
                const sum = invoice.line_items.reduce((sum, item) => {
                    const sign = item.credit ? -1 : 1;
                    return sum + item.amount * sign;
                }, 0);
                // invoice amount must not be negative
                if (sum < 0) {
                    return true;
                }
            }

            // check if at least 1 contact email has been provided and selected as the addressee
            if (!this.contact_emails || this.contact_emails.length === 0) {
                return true;
            }
            if (
                !this.contact_emails.some(
                    (c) => c.type === EMAIL_TYPE.ADDRESSEE
                )
            ) {
                return true;
            }

            return false;
        },
    },
    watch: {
        payment_plan: {
            handler(plan) {
                this.initialiseInvoices(plan);
            },
            immediate: true,
        },
        reference(val) {
            this.$emit('reference', val);
        },
    },
    methods: {
        initialiseInvoices(plan) {
            if (plan) {
                this.reference = plan.reference;
                this.purchase_order = plan.purchase_order;

                this.invoices = [];
                let startDate = momentWithTz(plan.start_date);

                // create payment plan invoices based on provided parameters
                for (let i = 0; i < this.payment_plan.quantity; i++) {
                    let invoiceDate = null;
                    let dueDate = null;
                    if (plan.is_scheduled) {
                        invoiceDate = momentWithTz(startDate).add(
                            i * plan.occurrence_interval,
                            plan.occurrence_period
                        );
                        dueDate = this.calculateDueDate(
                            invoiceDate,
                            plan.due_value,
                            plan.due_terms
                        );
                    }
                    this.invoices.push({
                        sequence: i + 1,
                        reference_template: plan.reference,
                        is_scheduled: plan.is_scheduled,
                        schedule_date: invoiceDate,
                        due_date: dueDate,
                        due_value: plan.due_value,
                        due_terms: plan.due_terms,
                        line_items: plan.selected_line_items.map((item) => {
                            return {
                                credit: item.credit ?? false,
                                description: item.title,
                                plan_cost: item.selected_amount,
                                amount: item.selected_amount / plan.quantity,
                                qty: item.credit ? 1 : item.qty / plan.quantity,
                                rate: this.estimate.rate,
                                discount: item.discount,
                                estimate_group: item.credit
                                    ? 'CREDIT'
                                    : this.$fire.doc(
                                          `estimate_groups/${this.$options.filters.fireRef2id(
                                              item.id
                                          )}`
                                      ),
                            };
                        }),
                    });
                }

                this.calculatePlanTotals();
            }
        },
        toggleReferenceTemplateHelp() {
            this.showReferenceTemplateHelp = !this.showReferenceTemplateHelp;
        },

        handleCancel() {
            this.$emit('cancel');
        },
        handleCreate() {
            this.$emit('create', this.invoices, this.contact_emails);
        },

        handleInvoiceUpdate(invoice) {
            const index = this.invoices.findIndex(
                (i) => i.sequence === invoice.sequence
            );
            if (index >= 0) {
                this.invoices[index] = invoice;
                this.calculatePlanTotals();
            }
        },

        calculatePlanTotals() {
            const totals = this.invoices.reduce((total, invoice) => {
                return invoice.line_items.reduce((invoiceTotal, lineItem) => {
                    // start at zero if no value has been set yet
                    const key = lineItem.credit
                        ? 'CREDIT'
                        : lineItem.estimate_group.id;
                    invoiceTotal[key] ??= 0;
                    invoiceTotal[key] += lineItem.amount;
                    return invoiceTotal;
                }, total);
            }, {});

            // prepare total line items
            this.plan_totals = this.payment_plan.selected_line_items.map(
                (ppli) => {
                    return {
                        description: ppli.title,
                        plan_cost: ppli.selected_amount,
                        discount: ppli.discount,
                        amount: totals[ppli.id],
                    };
                }
            );
        },

        handleUpdateContactEmails(emails) {
            this.contact_emails = emails;
        },

        handleUpdatePurchaseOrder() {
            this.$emit('purchase_order', this.purchase_order);
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    overflow: auto;

    .paymentplan-header {
        font-size: 11px;
        margin-bottom: 6px;

        span {
            padding-left: 5px;
        }
    }

    .paymentplan-form-fields {
        ::v-deep .input-value {
            width: 100%;

            &.read-only,
            &.read-only input,
            input:read-only {
                background: transparent;
                color: #a0a0a0;
                pointer-events: none;
                border: none;
            }

            input {
                padding-left: 5px !important;
                padding-right: 5px !important;
                text-align: center;
            }

            &.left input {
                text-align: left;
            }
        }
    }

    .footer-note {
        font-size: 11px;
        padding-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .paymentplan-form-footer {
        margin-top: 20px;
        text-align: right;
    }
}
</style>
